<template>
  <div style="max-width: 100rem; margin: auto;">
    <b-card>
      <b-row>
        <!-- <b-col cols="12">
          <b-form-group
            label="Main Proxy"
            label-for="h-first-name"
            label-cols-md="2"
          >
            <b-input-group>
              <b-form-input
                id="h-first-name"
                placeholder="Main Proxy"
                disabled
                v-model="main_proxy"
              />
            </b-input-group>
            
          </b-form-group>
        </b-col> -->
        <b-col cols="12">
          <b-form-group
            label="Main Server"
            label-for="h-first-name"
            label-cols-md="2"
          >
            <b-input-group>
              <b-form-input
                id="h-first-name"
                placeholder="User Proxy"
                disabled
                value="get.fastproxy.vip:2086"
              />
           
            </b-input-group>
            
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Main user"
            label-for="h-first-name"
            label-cols-md="2"
          >
            <b-input-group>
              <b-form-input
                id="h-first-name"
                placeholder="User Proxy"
                disabled
                v-model="user_proxy"
              />
            </b-input-group>
            
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Your Proxy Pass"
            label-for="h-first-name"
            label-cols-md="2"
          >
            <b-input-group>
              <b-form-input
                id="h-first-name"
                placeholder="Proxy Pass"
                disabled
                v-model="proxy_pass"
              />
              <b-input-group-append>
                <b-button variant="outline-primary" @click="onChangeProxyPass">
                  Change
                </b-button>
              </b-input-group-append>
            </b-input-group>
            
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Your API Key"
            label-for="h-first-name"
            label-cols-md="2"
          >
            <b-input-group>
              <b-form-input
                id="h-first-name"
                placeholder="API Key"
                disabled
                v-model="apikey"
              />
              <b-input-group-append>
                <b-button variant="outline-primary" @click="onChangeApiKey">
                  Change
                </b-button>
              </b-input-group-append>
            </b-input-group>
            
          </b-form-group>
        </b-col>
        
        <b-col cols="12">
          <b-form-group
            label="Admin Key Port"
            label-for="h-first-name"
            label-cols-md="2"
          >
            <b-input-group>
              <b-form-input
                id="h-first-name"
                placeholder="Proxy Pass"
                disabled
                v-model="keyport"
              />
              <b-input-group-append>
                <b-button variant="outline-primary" @click="onChangeKeyport">
                  Change
                </b-button>
              </b-input-group-append>
            </b-input-group>
            
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Your API TOKEN: <b-badge variant="success">{{userInfo.apikey}}</b-badge> -->
    </b-card>
    
    <b-tabs align="left">
      <b-tab
        title="Using the API"
        active
      >
        <b-card title="Making API Requests">
          <dl class="row dlborder"> 
            <dt class="col-sm-3 text-right" >
              Domain
            </dt>
            <dd class="col-sm-9 font-weight-bolder">
              api-socks.fastproxy.vip
            </dd>
          </dl>
          <dl class="row dlborder">
            <dt class="col-sm-3 text-right">
              URL
            </dt>
            <dd class="col-sm-9 font-weight-bolder">
              /api/v1/proxies/[your keyport]
            </dd>
          </dl>
          <dl class="row dlborder">
            <dt class="col-sm-3 text-right">
              Service
            </dt>
            <dd class="col-sm-9 font-weight-bolder">
              api_key={{userInfo.apikey}}
            </dd>
          </dl>
          <dl class="row dlborder">
            <dt class="col-sm-3 text-right">
              Method
            </dt>
            <dd class="col-sm-9">
              <div class="font-weight-bolder">
                GET / POST / PUT
              </div>
               <div class="font-weight-bolder">
                To simulate PUT, set Content-Type header to application/x-www-form-urlencoded and add a field named _method with the value PUT
              </div>
               <div class="font-weight-bolder">
                Example:
              </div>
              <pre class="code">
                <vue-perfect-scrollbar :settings="perfectScrollbarSettings" style="padding: 0;">
                  $.ajax({
                      url: "/api/v1/proxies/[your keyport]?api_key=[your apikey]",
                      data: "_method=PUT&keyport=[your keyport]&port=10001",
                      dataType: "json",
                      type : "POST",
                      success : function(r) {
                        //console.log(r);
                      }
                  });
                </vue-perfect-scrollbar>
              </pre>
            </dd>
          </dl>
          <dl class="row dlborder">
            <dt class="col-sm-3 text-right">
              HTTP Form Payload
            </dt>
            <dd class="col-sm-9">
              <div class="font-weight-bolder">Content-Type: x-www-form-urlencoded</div>
              <div class="font-weight-bolder">Method: GET/PUT/POST</div>
              <div class="font-weight-bolder">Content:</div>
              <vue-perfect-scrollbar :settings="perfectScrollbarSettings">
                <pre class="code" style="padding: 2rem; margin-top: 1rem">_method=PUT&keyport=[your keyport]&port=10001</pre>
              </vue-perfect-scrollbar>
            </dd>
          </dl>
          <dl class="row dlborder">
            <dt class="col-sm-3 text-right">
              JSON Payload (must be PUT)
            </dt>
            <dd class="col-sm-9">
              <div class="font-weight-bolder">Content:</div>
              <vue-perfect-scrollbar :settings="perfectScrollbarSettings" style="padding: 0;">
                <pre class="code" style="padding: 2rem; margin-top: 1rem">
                  {
                    "keyport": [your keyport],
                    "option": [your value]
                  }
                  Example:
                  {
                      "keyport": "shadowkeyport1234",
                      "country": "US"
                  }
                </pre>
              </vue-perfect-scrollbar>
            </dd>
          </dl>
        </b-card>
      </b-tab>
      <b-tab title="API References">
        <b-card>
          <app-collapse>
            <!-- <app-collapse-item title="1. GET Authorized IPs">
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  URL
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  /api/v1/backconnect/{membership}/authorized-ips/
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  URL Params
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  membership=[string] (required)
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                Success Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 200</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": null,
                        "code": 200,
                        "data": [
                            "1.1.1.1",
                            "1.1.1.2"
                        ]
                    }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                 <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 401</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Unauthorized",
                        "code": 401
                    }
                  </pre>
                  <div>This error means that you have a missing or invalid api_token, please check the making api requests section.</div>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 404</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Membership not found",
                        "code": 404
                    }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 503</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Membership status is: [Status]"
                        "code": 503
                    }
                  </pre>
                </dd>
              </dl>
            </app-collapse-item>
            <app-collapse-item title="2. PUT Authorized IPs">
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  URL
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  /api/v1/backconnect/{membership}/authorized-ips/
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Method
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  PUT
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  URL Params
                </dt>
                 <dd class="col-sm-9 font-weight-bolder">
                  membership=[string] (required)
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                Payload
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem; margin-top:1rem">
                    {
                        "ips": [
                            "1.1.1.1",
                            "1.1.1.2"
                        ]
                    }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Success Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 200</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem; margin-top:1rem">
                    {
                        "error": null,
                        "code": 200,
                        "data": [
                          "1.1.1.1",
                          "1.1.1.2"
                        ]
                    }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                 <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 401</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Unauthorized",
                        "code": 401
                    }
                  </pre>
                  <div>This error means that you have a missing or invalid api_token, please check the making api requests section.</div>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 404</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Membership not found",
                        "code": 404
                    }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 503</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Membership status is: [Status]"
                        "code": 503
                    }
                  </pre>
                </dd>
              </dl>
            </app-collapse-item>
            <app-collapse-item title="3. GET GEO">
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  URL
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  /api/v1/backconnect/{membership}/geo/
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Method
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  GET
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  URL Params
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  membership=[string] (required)
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                Success Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem; margin-top:1rem">
                    {
                      "error": null,
                      "code": 200,
                      "geo": "US"
                  }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 401</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Unauthorized",
                        "code": 401
                    }
                  </pre>
                  <div>This error means that you have a missing or invalid api_token, please check the making api requests section.</div>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 404</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Membership not found",
                        "code": 404
                    }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 403</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Membership is not GEO",
                        "code": 403
                    }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 503</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Membership status is: [Status]"
                        "code": 503
                    }
                  </pre>
                </dd>
              </dl>
            </app-collapse-item>
            PUT GEO -->
            
            <!-- GET PROXIES -->
            <app-collapse-item title="1. GET List Proxies">
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  URL
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  /api/v1/proxies/shadowkeyport1234?api_key={{apikey}}
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Method
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  GET
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                Success Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem; margin-top:1rem">
                    [
                      {
                          "userproxy":" zone-{user}-port-2086",
                          "passproxy":"f@#FSGSDf34fFSgSdfsd",
                          "port":2086,
                      }
                    ]
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 401</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Unauthorized",
                        "code": 401
                    }
                  </pre>
                  <div>This error means that you have a missing or invalid api_token, please check the making api requests section.</div>
                </dd>
              </dl>
            </app-collapse-item>
            <app-collapse-item title="2. Change IP">
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  URL
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  /api/v1/proxies/[your keyport]/ip?port=[your port]&api_key={{apikey}}
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Method
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  GET
                </dd>
              </dl>
              <!-- <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                Body
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem; margin-top:1rem">
                    {
                      "port": 10001,
                    }
                  </pre>
                </dd>
              </dl> -->
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                Success Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem; margin-top:1rem">
                    {
                      "success": true,
                    }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 401</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Unauthorized",
                        "code": 401
                    }
                  </pre>
                  <div>This error means that you have a missing or invalid api_token, please check the making api requests section.</div>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 200</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Keyport or port is not equals",
                        "success": false
                    }
                  </pre>
                </dd>
              </dl>
            </app-collapse-item>
            <app-collapse-item title="3. Change Geo">
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  URL
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  /api/v1/proxies/[your keyport]/geo?api_key={{apikey}}
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Method
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  POST
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                Body
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem; margin-top:1rem">
                    {
                      "port": 10001,
                      "geo": "US"
                    }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                Success Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem; margin-top:1rem">
                    {
                      "success": true,
                    }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 401</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Unauthorized",
                        "code": 401
                    }
                  </pre>
                  <div>This error means that you have a missing or invalid api_token, please check the making api requests section.</div>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 200</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Keyport or port is not equals",
                        "success": false
                    }
                  </pre>
                </dd>
              </dl>
            </app-collapse-item>
            <app-collapse-item title="4. Change Rotate Time">
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  URL
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  /api/v1/proxies/[your keyport]/rotate?api_key={{apikey}}
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Method
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  POST
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                Body
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem; margin-top:1rem">
                    {
                      "port": 10001,
                      "rotate": 10
                    }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                Success Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem; margin-top:1rem">
                    {
                      "success": true,
                    }
                  </pre>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 401</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Unauthorized",
                        "code": 401
                    }
                  </pre>
                  <div>This error means that you have a missing or invalid api_token, please check the making api requests section.</div>
                </dd>
              </dl>
              <dl class="row dlborder"> 
                <dt class="col-sm-3 text-right" >
                  Error Response
                </dt>
                <dd class="col-sm-9 font-weight-bolder">
                  <div>HTTP Code: 200</div>
                  <div>Content:</div>
                  <pre class="code" style="padding-top: 1rem">
                    {
                        "error": "Keyport or port is not equals",
                        "success": false
                    }
                  </pre>
                </dd>
              </dl>
            </app-collapse-item>
          </app-collapse>
        </b-card>
      </b-tab>
    </b-tabs>
    
    <b-card style="height: 30rem;">
      <b-card-header class="pb-50">
        <b-card-title>White IP</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Line by line IP addresses, If white ip is empty, all ip will be accepted"
              label-for="h-first-name">
              <b-form-textarea
              v-model="wips"
              rows="10"
              placeholder="White ip"></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button variant="primary" :disabled="onproceess" @click="onUpdateWhiteIP">Save</b-button>
          </b-col>
        </b-row>
        
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText,BTabs,BTab, BFormTextarea,BBadge, BRow,BCol,BForm,BFormInput, BFormGroup, BInputGroupAppend,  BCardTitle,
  BButton, BInputGroup,BCardHeader, BCardBody
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import RepositoryFactory from '../../api/RepositoryFactory'
const UsersRepository = RepositoryFactory.get('user')
const NPORT = RepositoryFactory.get('nport')

export default {
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BTabs,
    BTab,BFormTextarea,
    VuePerfectScrollbar,
    AppCollapse,
    AppCollapseItem,
    BBadge,
    BRow,BCol,BForm,BFormInput, BFormGroup, BInputGroupAppend,
    BButton,BInputGroup
  },
  data(){
    return{
      userInfo: this.$store.getters['auth/userInfo'],
      apikey: this.$store.getters['auth/userInfo'].apikey,
      proxy_pass: this.$store.getters['auth/userInfo'].proxy_pass,
      keyport: this.$store.getters['auth/userInfo'].keyport,
      user_proxy: `zone-${this.$store.getters['auth/userInfo'].username}.vip-port-[xxxxx]`,
      wips:"",
      onproceess: false
    }
  },
  created(){
    //console.log(this.$store.getters['auth/userInfo'])
    this.$gtag.event('route', {state: 'api-page'})
    NPORT.getWhiteIP().then(rs=>{
      this.wips = rs.data.data.filter(x=>x != "").join('\n')
    })
  },
  setup(){
    const perfectScrollbarSettings = {
      maxScrollbarLength: 120,
      wheelPropagation: false,
      suppressScrollY: true,
      useBothWheelAxes: true
    }
    

    return {
      perfectScrollbarSettings,
    }
  },
  methods: {
    async onUpdateWhiteIP(){
      this.onproceess = true
      let arr = this.wips.split('\n').filter(x => x != "")

      //Check arr element is valid ip
      let check = true
      arr.forEach(ip=>{
        if(!ip.match(/^(\d{1,3}\.){3}\d{1,3}$/)){
          check = false
        }
      })

      if (check){
        NPORT.updateWhiteIP({ips: arr}).then(rs=>{
          if(rs.data.success){
            this.$toast.success('Update white ip success')
          }
        })
      }else{
       
        this.$toast.error('IP format is invalid')
      }
      this.onproceess = false
    },
    onChangeApiKey(){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          UsersRepository.changeApiKey().then(rs=>{
            if(rs.data.success){
              this.apikey = rs.data.apikey
              this.$store.dispatch('auth/get_uinfo')
            }
          })
        }
      })
    },
    onChangeProxyPass(){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          UsersRepository.changeProxyPass().then(rs=>{
            if(rs.data.success){
              this.proxy_pass = rs.data.proxy_pass
              this.$store.dispatch('auth/get_uinfo')
            }
          })
        }
      })
    },
    onChangeKeyport(){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          UsersRepository.changeKeyPort().then(rs=>{
            if(rs.data.success){
              this.keyport = rs.data.keyport
              this.$store.dispatch('auth/get_uinfo')
            }
          })
        }
      })
    }
  }
}
</script>

<style>
  .dlborder{
    border-bottom: 1px solid #e9eff5;
  }
  .code{
    overflow-x: auto;
    border-radius: .4rem;
    background-color: #f8fbfd;
    border: 1px solid #e9eff5;
  }
</style>
